<template>
  <div>
    <page-header :isIndex="2"></page-header>
    <div class="wrapper">
      <div class="contents flex justify-between">
        <div class="version">
          <div class="down-header flex">
            <div class="header-icon"><i class="icon-search"></i></div>
            <span
              >查询报告--
              提交检测后，请耐心等待检测完成，通过检测卡号查询下载报告即可。</span
            >
          </div>
          <div class="container-area">
            <div class="down-search">
              <div class="flex">
                <div class="search-lable">检测卡号:</div>
                <div class="search-input">
                  <input
                    type="text"
                    class="inputs"
                    v-model="ups.orderInfo"
                    placeholder="请输入检测卡号或商户单号"
                  />
                </div>
                <div class="search-submit" @click="handleCheck">查询报告</div>
                <div
                  class="search-submit search-clear"
                  v-if="ups.orderInfo"
                  @click="handleClear"
                >
                  清空
                </div>
              </div>
            </div>
            <div class="down-table">
              <div class="down-header flex">
                <div class="header-icon"><i class="icon-list"></i></div>
                <span class="red"
                  >注意：系统报告保留72小时，请及时下载报告</span
                >
                <span>(共找到{{ tableData.length }}篇报告)</span>
              </div>
              <table class="tables">
                <thead class="tables-header">
                  <tr>
                    <th v-for="(item, index) in cols" :key="index">
                      {{ item.name }}
                      <span class="red" v-if="item.type == 1">【必填】</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="tables-tbody">
                  <tr v-for="(item, index) in tableData" :key="index">
                    <td>{{ item.Title }}</td>
                    <td>{{ item.author }}</td>
                    <td>{{ item.SubmitTime }}</td>
                    <td>{{ item.detectStatus }}</td>
                    <td>
                      <span
                        v-if="item.detectStatus == '检测成功'"
                        class="blue"
                        @click="handleDown(item)"
                        >下载</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="!!item.TipFile"
                        class="blue"
                        @click="handleDownRed(item)"
                        >下载</span
                      >
                      <span
                        v-if="!!item.ErrorTipInfo"
                        class="blue"
                        @click="handleOpenRed(item)"
                        >
                        <svg class="icon" style="width: 1.5em;height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14354"><path d="M512.8 512m-423 0a423 423 0 1 0 846 0 423 423 0 1 0-846 0Z" fill="#FF7575" p-id="14355"></path><path d="M481.3 590.7c5.3 15.8 15.8 26.2 31.5 26.2 15.8 0 26.2-10.5 31.5-26.2l21-288.7c0-31.5-26.2-52.5-52.5-52.5-31.5 0-52.5 26.2-52.5 57.8l21 283.4z m31.5 78.8c-31.5 0-52.5 21-52.5 52.5s21 52.5 52.5 52.5 52.5-21 52.5-52.5-21-52.5-52.5-52.5z m0 0" fill="#FFFFFF" p-id="14356"></path></svg>
                        </span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.detectStatus == '检测成功'"
                        class="red"
                        @click="handleDel(item)"
                        >删除</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="no-file" v-if="tableData.length == 0">
              <div class="no-content">
                <div class="no-title flex justify-center">
                  <div class="no-icon cover"></div>
                  <div class="no-text">没有查询出报告！</div>
                </div>
                <div class="no-des">
                  温馨提示:
                  请确认输入订单编号正确或已成功提交检测，如有疑问请联系在线客服。
                </div>
              </div>
            </div>
            <div class="down-footer">
              <div class="down-header flex">
                <div class="header-icon"><i class="icon-fullscreen"></i></div>
                <span> 下载报告须知</span>
              </div>
              <div class="footer-content">
                <div
                  class="foot-item flex"
                  v-for="(item, index) in foots"
                  :key="index"
                >
                  <div class="item-title" v-html="item.name"></div>
                  <div class="item-tip" v-html="item.tip"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <page-side></page-side>
      </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
const cols = [
  { name: "论文标题", type: 0 },
  { name: "论文作者", type: 0 },
  { name: "检测时间", type: 0 },
  { name: "检测状态", type: 0 },
  { name: "检测报告", type: 0 },
  { name: "描红报告", type: 0 },
  { name: "删除报告", type: 0 },
];
const foots = [
  {
    name: "<span class='red'>如检测失败</span>",
    tip:
      "在系统“检测状态”为<span class='red'>检测失败</span>时候，请确认文档状态是否加密、只读、乱码等，确认修改后联系客服。",
  },
  {
    name: "<span class='blue'>下载报告</span>",
    tip: "系统报告仅保留 72 小时，请及时下载报告到本地设备以免造成损失。",
  },
  {
    name: "<span class='blue'>删除报告</span>",
    tip:
      "同学们在报告检测完成后，可以自行删除检测报告，删除后不可恢复，请删除前确认。",
  },
  {
    name: "<span class='blue'>报告打开方式</span>",
    tip: "系统报告为zip压缩包，请下载解压软件再打开",
  },
  {
    name: "<span class='blue'>描红报告</span>",
    tip:
      "该报告是赠送报告，提交查重时根据个人意愿自主勾选，部分文档因格式问题无法描红，不以描红报告相关问题为理由退款（如忘记勾选、报告不满意，描红不成功等）",
  },
];
import { GetOrderInfo, DeleteOrderInfo } from "@/utils/http";
import api from "@/utils/api";
import { BASE_URL } from "@/store/mutation-types";
export default {
  data() {
    return {
      ups: {
        orderInfo: "",
      },
      result: {
        total: 0,
      },
      cols,
      foots,
      tableData: [],
    };
  },
  mounted() {
    
    layer.closeAll();
  },
  methods: {
    handlePic() {
      window.open("findnumbers.png", "_blank");
    },
    handleClear() {
      this.tableData = [];
      this.ups.orderInfo = "";
    },
    handleCheck() {
      this.tableData = [];
      if (!this.ups.orderInfo) {
        return this.msgFn("请输入卡号");
      }
      var layerIndex = layer.load(1, {
        shade: [0.1, "#000"],
      });

      GetOrderInfo(this.ups)
        .then((res) => {
          let data = res.data;
          layer.closeAll();
          this.tableDataFn(data);
        })
        .catch((err) => {
          layer.closeAll();
          this.msgFn(err.info || "接口错误");
        });
    },
    tableDataFn(data) {
      if (!data) {
        this.tableData = [];
      }
      if (Array.isArray(data)) {
        this.tableData = data;
      } else {
        this.tableData = [data];
      }
    },
    handleDownRed(data){
      let url =
        BASE_URL +"/t2"+
        api.GetResultFile +
        "?orderInfo=" +
        data.tid +
        "&id=" +
        data.Id+"&TipFile=1";
      window.open(url, "_blank");
    },
    handleOpenRed(data){
      layer.alert(data.ErrorTipInfo)
    },
    handleDown(data) {
      let url =
        BASE_URL +"/t2"+
        api.GetResultFile +
        "?orderInfo=" +
        data.tid +
        "&id=" +
        data.Id;
      window.open(url, "_blank");
    },
    msgFn(data) {
      layer.alert(data);
    },
    handleDel(data) {
      let ups = {
        orderInfo: data.tid,
        id: data.Id,
      };
      let that = this;
      layer.confirm(
        "确定删除文件吗?",
        {
          btn: ["确定", "取消"],
        },
        function() {
          layer.closeAll();
          DeleteOrderInfo(ups)
            .then((res) => {
              if (res.code == 200) {
                that.msgFn("删除成功");
                that.tableData = [];
                that.ups.orderInfo = "";
              }
            })
            .catch((err) => {
              that.msgFn(err.info);
            });
        },
        function() {
          layer.closeAll();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 1200px;
  min-width: 1200px;
}
.contents {
  position: relative;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 80px;

  .version {
    width: 100%;
    background: #fff;
    position: relative;
  }
}

.down-header {
  height: 38px;
  padding-top: 30px;
  width: 100%;
  border-bottom: 1px dashed #cdcdcd;
  span {
    font-size: 14px;
    padding-left: 10px;
    font-weight: 700;
    line-height: 48px;
  }
  .header-icon {
    padding-top: 15px;
  }
}
.down-search {
  padding: 30px 0 0 45px;
  height: 40px;
  line-height: 40px;
}
.search-lable {
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 15px;
}
.search-input {
  width: 260px;
  height: 40px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 0 10px;
  .inputs {
    width: 100%;
    border: 0;
    height: 38px;
    line-height: 38px;
    outline: none;
  }
}
.search-submit {
  margin-left: 15px;
  height: 40px;
  background-color: #c63932;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  width: 130px;
  border-radius: 4px;
  border: 1px solid #c63932;
  cursor: pointer;
  &:hover {
    background: rgba(#c63932, 0.9);
  }
  &.search-clear {
    color: #333;
    margin-left: 10px;
    background: #fff;
    border: 1px solid #ccc;
  }
}
.search-clear:hover {
  background: #fff;
}
.search-pic {
  color: red;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
}
.down-table {
  padding: 10px 0;
  .talbe-title {
    height: 38px;
  }
  .red {
    padding: 0 5px;
    color: red;
  }
}
.no-file {
  height: 360px;
  position: relative;
  .no-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .no-title {
    height: 50px;
    .no-icon {
      width: 30px;
      height: 30px;
      margin-top: 4px;
      background-image: url("~@/assets/no.png");
    }
    .no-text {
      padding-left: 10px;
      font-size: 26px;

      color: #a9a6a6;
    }
  }
  .no-des {
    padding-top: 20px;
    color: #a9a6a6;
    font-size: 12px;
    text-align: center;
  }
}
.tables {
  width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    text-align: center;
    border-bottom: 2px solid green;
    border-right: 1px dashed #cdcdcd;
  }
  tr {
    height: 40px;
    line-height: 40px;
  }
  td {
    text-align: center;
    border-right: 1px dashed #cdcdcd;
  }
  .red,
  .blue {
    cursor: pointer;
  }
  .blue {
    color: #428bca;
  }
  .tables-tbody {
    td {
      border-bottom: 1px dashed #cdcdcd;
    }
  }
}
.tables-header {
  width: 100%;
}

.down-footer {
  padding: 20px 0;
}
.footer-content {
  padding-top: 20px;
}
.container-area {
  position: relative;
  padding-top: 24px;
}
</style>
