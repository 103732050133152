import request from '@/utils/request'

import api from "@/utils/api"

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function SubmitFile(parameter) {
  return request({
    url: api.SubmitFile,
    method: 'post',
    data: parameter,
    headers:{'Content-Type':'multipart/form-data'}
  })
}

export function Test(parameter) {
  return request({
    url: api.test,
    method: 'post',
    data: parameter,
    headers:{'Content-Type':'multipart/form-data'}
  })
}

export function GetOrderInfo(parameter) {
  return request({
    url: api.GetOrderInfo,
    method: 'post',
    data: parameter,
  })
}

export function GetResultFile(parameter) {
  return request({
    url: api.GetResultFile,
    method: 'post',
    data: parameter,
    headers:{'Content-Type':'multipart/form-data'}
  })
}

export function DeleteOrderInfo(parameter) {
  return request({
    url: api.DeleteOrderInfo,
    method: 'post',
    data: parameter,
  })
}




